// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require cocoon

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
// jquery は expose-loader で global に配置される
// https://www.npmjs.com/package/expose-loader/v/1.0.3
// application.js は全てのページで呼ばれるので、以下の import を他の js で指定する必要はない
import $ from "expose-loader?exposes[]=$&exposes[]=jQuery!jquery"
import 'bootstrap'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.ja'
import 'bootstrap-timepicker/js/bootstrap-timepicker'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'slick-carousel'
import 'jquery-jpostal-ja'

require("chart.js/dist/chart.min.js")
require("@nathanvda/cocoon")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', function () {
  $('[data-toggle="tooltip"]').tooltip();

  // ブラウザの戻るボタンで戻ってきた場合は強制的にリロードする
  history.replaceState(null, document.getElementsByTagName('title')[0].innerHTML, null);
  window.addEventListener('popstate', function (e) {
    window.location.reload();
  });

  // イベント開始日の入力値によって終了日の選択できる範囲を絞る
  // FIXME: 画面固有の処理だけどここでしか動かないので暫定対応
  $('#aruko_fes_form_event_start_date').datepicker().on('changeDate', function (e) {
    let start_date = new Date(e.date);
    let end_date = new Date(e.date);
    start_date.setDate(e.date.getDate() + 27);
    end_date.setDate(e.date.getDate() + 44);

    $('#aruko_fes_form_event_end_date').datepicker('setStartDate', start_date);
    $('#aruko_fes_form_event_end_date').datepicker('setEndDate', end_date);
    $('#aruko_fes_form_event_end_date').datepicker('update');
  });

  // イベント開始日の入力値によって終了日の選択できる範囲を絞る
  // FIXME: 画面固有の処理だけどここでしか動かないので暫定対応
  $('#team_aruko_fes_form_event_start_date').datepicker().on('changeDate', function (e) {
    let start_date = new Date(e.date);
    let end_date = new Date(e.date);
    start_date.setDate(e.date.getDate() + 13);
    end_date.setDate(e.date.getDate() + 44);

    $('#team_aruko_fes_form_event_end_date').datepicker('setStartDate', start_date);
    $('#team_aruko_fes_form_event_end_date').datepicker('setEndDate', end_date);
    $('#team_aruko_fes_form_event_end_date').datepicker('setDate', '');
    $('#team_aruko_fes_form_negotiation_date').datepicker('setDate', '');
    $('#team_aruko_fes_form_event_end_date').datepicker('update');
  });

  if (!!$('#team_aruko_fes_form_event_start_date').val()) {
    let start_date = new Date($('#team_aruko_fes_form_event_start_date').val());
    let end_date = new Date($('#team_aruko_fes_form_event_start_date').val());
    start_date.setDate(start_date.getDate() + 13);
    end_date.setDate(end_date.getDate() + 44);

    $('#team_aruko_fes_form_event_end_date').datepicker('setStartDate', start_date);
    $('#team_aruko_fes_form_event_end_date').datepicker('setEndDate', end_date);
    $('#team_aruko_fes_form_event_end_date').datepicker('update');
  }

  // イベント終了日の入力値によって報告希望日の選択できる範囲を絞る
  // FIXME: 画面固有の処理だけどここでしか動かないので暫定対応
  $('#team_aruko_fes_form_event_end_date').datepicker().on('changeDate', function (e) {
    if (!e.date) { return }
    let start_date = new Date(e.date);
    start_date.setDate(e.date.getDate() + 21);

    $('#team_aruko_fes_form_negotiation_date').datepicker('setStartDate', start_date);
    $('#team_aruko_fes_form_negotiation_date').datepicker('setDate', '');
    $('#team_aruko_fes_form_negotiation_date').datepicker('update');
  });

  if (!!$('#team_aruko_fes_form_event_end_date').val()) {
    let start_date = new Date($('#team_aruko_fes_form_event_end_date').val());
    start_date.setDate(start_date.getDate() + 21);

    $('#team_aruko_fes_form_negotiation_date').datepicker('setStartDate', start_date);
    $('#team_aruko_fes_form_negotiation_date').datepicker('update');
  }

  $('#company-applications-form-start_date').datepicker().on('changeDate', function (e) {
    let start_date = new Date(e.date);
    start_date.setMonth(e.date.getMonth() + 3);
    start_date.setDate(start_date.getDate() + 14);

    $('#company-applications-form-negotiation_date').datepicker('setStartDate', start_date);
    $('#company-applications-form-negotiation_date').datepicker('update');

    if ( !!$(this).val() ) {
      $(this).removeClass('is-invalid');
    }
  });

  $('#company-applications-form-negotiation_date').datepicker().on('changeDate', function (e) {
    if ( !!$(this).val() ) {
      $(this).removeClass('is-invalid');
    }
  });

  $('#team_aruko_fes_form_event_start_date').datepicker().on('changeDate', function (e) {
    if ( !!$(this).val() ) {
      $(this).removeClass('is-invalid');
    }
  });
  $('#team_aruko_fes_form_event_end_date').datepicker().on('changeDate', function (e) {
    if ( !!$(this).val() ) {
      $(this).removeClass('is-invalid');
    }
  });
  $('#team_aruko_fes_form_negotiation_date').datepicker().on('changeDate', function (e) {
    if ( !!$(this).val() ) {
      $(this).removeClass('is-invalid');
    }
  });
});
